@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

h1, h2, h3 {
  font-family: 'Ubuntu', sans-serif;
}

